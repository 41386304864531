// import { message, notification } from 'antd'
import { message, notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import store from 'store'
import actions from './actions'

const jwts = {
  getUserProcurement: jwt.getUserProcurement,
  getProcurementFormFee: jwt.getProcurementFormFee,
  payProcurementFormFee: jwt.payProcurementFormFee,
  requestForProcurement: jwt.requestForProcurement,
  editProcurementRequest: jwt.editProcurementRequest,
  getAllProcurements: jwt.getAllProcurements,
  cancelProcurement: jwt.cancelProcurement,
  getSingleProcurementDetails: jwt.getSingleProcurementDetails,
  getEditProcurementDetails: jwt.getEditProcurementDetails,
  payProcurementRepayment: jwt.payProcurementRepayment,
  getProcurementPaymentDuration: jwt.getProcurementPaymentDuration,
  procurementUserAutocomplete: jwt.procurementUserAutocomplete,
  specialProcurementRequest: jwt.specialProcurementRequest,
  getProcurementWaiver: jwt.getProcurementWaiver,
  viewGuarantors: jwt.viewProcureGuarantors,
  GurantorAcceptDecline: jwt.GurantAcceptDecline,
  getProcureDetails: jwt.getProcureDetails,
  getGuarantorStatus: jwt.getProcureGuarantorStatus,
}

export function* GET_USER_PROCUREMENT() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserProcurement)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        data: success.data,
        procurementSummary: success.data.procurement_summary
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* GET_PROCUREMENT_FORM_FEE() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProcurementFormFee)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        procurementFormFee: success.data,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAY_PROCUREMENT_FORM_FEE({payload}) {
  console.log(payload)
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payProcurementFormFee, payload.paymentData)
  if (success.status) {
    yield put({type: 'procurement/REQUEST_FOR_PROCUREMENT', payload: payload.requestData})
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* REQUEST_FOR_PROCUREMENT({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.requestForProcurement, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'procurement/GET_USER_PROCUREMENT'})
    yield put({type: 'procurement/GET_ALL_PROCUREMENTS'})
    yield put({type: 'wallet/GET_USER_WALLET'})
    yield put({type: 'procurement/HANDLE_SECTION', payload: "home"})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* EDIT_PROCUREMENT_REQUEST({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.editProcurementRequest, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'procurement/GET_USER_PROCUREMENT'})
    yield put({type: 'procurement/GET_ALL_PROCUREMENTS'})
    yield put({type: 'wallet/GET_USER_WALLET'})
    yield put({type: 'procurement/HANDLE_SECTION', payload: "home"})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* CANCEL_PROCUREMENT({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.cancelProcurement, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'procurement/GET_USER_PROCUREMENT'})
    yield put({type: 'procurement/GET_ALL_PROCUREMENTS'})
    yield put({type: 'wallet/GET_USER_WALLET'})
    yield put({type: 'procurement/HANDLE_SECTION', payload: "home"})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}
export function* GET_GUARANTOR_STATUS({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getGuarantorStatus, payload)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        guaranteedStatus: data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}
export function* GUARANTOR_ACCEPT_DECLAIN({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.GurantorAcceptDecline, payload)
  if (success.status || success?.message?.includes("success")) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'procurement/GET_GUARANTORS_STATUS'})
    notification.success({
      message: "Success!",
      description: success?.message
    })
  }
  if (!success.status && !success?.message?.includes("success")) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success?.message
    })
  }
}
export function* GET_ALL_PROCUREMENTS() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllProcurements)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        pendingProcurement: Array.isArray(data?.pending) ? data?.pending : [data?.pending],
        ongoingProcurement: Array.isArray(data?.ongoing) ? data?.ongoing : [data?.ongoing],
        completedProcurement: Array.isArray(data?.completed) ? data?.completed : [data?.completed],
        cancelledProcurement: Array.isArray(data?.cancelled) ? data?.cancelled : [data?.cancelled]
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}
export function* GET_GUARANTORS_STATUS() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.viewGuarantors)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        pendingGuarantorLoan: data?.pending_request,
        ongoingGuarantorLoan: data?.approved_request,
        cancelledGuarantorLoan: data?.cancelled_request
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* GET_SINGLE_PROCUREMENT_DETAIL({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSingleProcurementDetails, payload)
  if (success.status) {
    const {data} = success
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        singleProcurementGuarantor: {members: data?.guarantors_member, non_members: data?.guarantors_non_member},
        singleProcurementSummary: data?.procurement_summary,
        singleProcurementTransaction: data?.procurement_trans
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_EDIT_PROCUREMENT_DETAIL({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getEditProcurementDetails, payload)
  if (success.status) {
    const {data} = success
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        editProcurementDetail: data
      }
    })
    yield put({type: 'procurement/HANDLE_SECTION', payload: "edit"})
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* PAY_PROCUREMENT_REPAYMENT({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payProcurementRepayment, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "procurement/GET_USER_PROCUREMENT"})
    yield put({type: "procurement/GET_ALL_PROCUREMENTS"})
    yield put({type: "procurement/GET_SINGLE_PROCUREMENT_DETAIL", payload: payload.id})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_REPAYMENT_DURATION() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProcurementPaymentDuration)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        repaymentDuration: success.data,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* GET_USER_AUTOCOMPLETE({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      searchLoading: true,
    }
  })
  const success = yield call(jwts.procurementUserAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        searchLoading: false,
        userAutocomplete: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        searchLoading: false,
        userAutocomplete: []
      }
    })
    message.error({
      content: success.message,
      key: "updatable"
    })
  }
}



export function* GET_PROCUREMENT_WAIVER() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    }
  })
  const success = yield call(jwts.getProcurementWaiver)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        waiverData: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        waiverData: success.data
      }
    })
    // notification.error({
    //   message: "Error!",
    //   description: success.message,
    // })
  }
}


export function* HANDLE_SECTION({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      pageSection: payload
    }
  })
}


// special procurement section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export function* HANDLE_SPECIAL_SECTION({payload}) {
  store.set("procurement_section", payload)
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      specialSection: payload
    }
  })
}

export function* GET_PROCURE_DETAILS({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProcureDetails, payload)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        guaranteedDetails: data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}
export function* HANDLE_SPECIAL_LOGIN_MODAL({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      specialLoginModal: payload
    }
  })
}


export function* REQUEST_FOR_SPECIAL_PROCUREMENT({payload}) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      specialLoading: true,
    }
  })
  const success = yield call(jwts.specialProcurementRequest, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        specialLoading: false,
        specialSubmittedData: success
      }
    })
    yield put({type: "procurement/HANDLE_SPECIAL_SECTION", payload: "success"})
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        specialLoading: false,
        specialSubmittedData: {}
      }
    })
    notification.error({
      message: "Error!",
      description: success.message,
    })
  }
}




export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_PROCUREMENT, GET_USER_PROCUREMENT),
    takeEvery(actions.GET_PROCUREMENT_FORM_FEE, GET_PROCUREMENT_FORM_FEE),
    takeEvery(actions.PAY_PROCUREMENT_FORM_FEE, PAY_PROCUREMENT_FORM_FEE),
    takeEvery(actions.REQUEST_FOR_PROCUREMENT, REQUEST_FOR_PROCUREMENT),
    takeEvery(actions.EDIT_PROCUREMENT_REQUEST, EDIT_PROCUREMENT_REQUEST),
    takeEvery(actions.GET_ALL_PROCUREMENTS, GET_ALL_PROCUREMENTS),
    takeEvery(actions.CANCEL_PROCUREMENT, CANCEL_PROCUREMENT),
    takeEvery(actions.GET_SINGLE_PROCUREMENT_DETAIL, GET_SINGLE_PROCUREMENT_DETAIL),
    takeEvery(actions.GET_EDIT_PROCUREMENT_DETAIL, GET_EDIT_PROCUREMENT_DETAIL),
    takeEvery(actions.PAY_PROCUREMENT_REPAYMENT, PAY_PROCUREMENT_REPAYMENT),
    takeEvery(actions.GET_REPAYMENT_DURATION, GET_REPAYMENT_DURATION),
    takeEvery(actions.GET_USER_AUTOCOMPLETE, GET_USER_AUTOCOMPLETE),
    takeEvery(actions.GET_PROCUREMENT_WAIVER, GET_PROCUREMENT_WAIVER),
    takeEvery(actions.HANDLE_SECTION, HANDLE_SECTION),


    // special procurement section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    takeEvery(actions.HANDLE_SPECIAL_SECTION, HANDLE_SPECIAL_SECTION),
    takeEvery(actions.GUARANTOR_ACCEPT_DECLAIN, GUARANTOR_ACCEPT_DECLAIN),
    takeEvery(actions.HANDLE_SPECIAL_LOGIN_MODAL, HANDLE_SPECIAL_LOGIN_MODAL),
    takeEvery(actions.REQUEST_FOR_SPECIAL_PROCUREMENT, REQUEST_FOR_SPECIAL_PROCUREMENT),
    takeEvery(actions.GET_GUARANTORS_STATUS, GET_GUARANTORS_STATUS),
    takeEvery(actions.GET_PROCURE_DETAILS, GET_PROCURE_DETAILS),
    takeEvery(actions.GET_GUARANTOR_STATUS, GET_GUARANTOR_STATUS),
  ])
}
