/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { Modal } from 'antd'
import React from 'react'

function DisclaimerModal({ visible, dispatch }) {
  return (
    <Modal
      visible={visible}
      title="Disclaimer Alert"
      onCancel={() => dispatch({ type: 'user/DISCLAIMER_MODAL', payload: false })}
      footer={false}
    >
      <div className="">
        <b> ZERO INTEREST MULTIPURPOSE COOPERATIVE SOCIETY LTD (ZIMCS) </b>
        <span>
          {' '}
          of 1B Baba Yusuf Close, Off Makinde Street, Alausa - Ikeja hereby informs all cooperators
          and the general public that it is not the same as and has no relationship with{' '}
        </span>
        <b> Abundant Treasure Multipurpose Cooperative Society. </b>
        <span> Any transaction or dealing in the name of </span>
        <b> ZIMCS </b> <span> with </span>
        <b> Abundant Treasure Multipurpose Cooperative Society </b>
        <span> is at the peril of the payer. </span>
        <br /> <br /> <br />
        <span>
          {' '}
          We equally hereby inform our prestigious Cooperators and General Public that the persons
          whose names given below are NO LONGER MEMBERS OF STAFF/FRANCHISE MANAGERS/ MARKETERS of{' '}
        </span>
        <b> ZERO INTEREST MULTIPURPOSE COOPERATIVE SOCIETY LTD (ZIMCS) </b>
        <span> and can no longer speak nor transact business in that capacity. </span>
        <br /> <br />
        <ol>
          <li>. Baruwa Haleemah</li>
          <li>. Akinbami Kazeem</li>
          <li>. Adekunle Tawakalitu</li>
          <li>. Jibowu Bolanle Sherifah</li>
          <li>. Sobayo Oluwaseun Mueez</li>
          <li>. Oginni Opeyemi Olorunfemi</li>
        </ol>
        <br />
        <p>
          The <b>ZIMCS</b> hereby disclaims, to the maximum extent permitted by law, all
          responsibility and all liability (including without limitation, liability in negligence)
          resulted from transaction carried out with any of the above persons in the capacity of
          Staff/Franchise Manager/ Marketer of <b>ZIMCS</b>.
        </p>
        <div className="text-center">
          <p>Signed</p>
          <b>Management (ZIMCS)</b>
          <p>Today 16th February, 2024</p>
        </div>
      </div>
    </Modal>
  )
}

export default DisclaimerModal
