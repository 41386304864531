/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'savings/SET_STATE',
  GET_REGULAR_SAVINGS: 'savings/GET_REGULAR_SAVINGS',
  DISABLE_AUTO_SAVE_PLAN: 'savings/DISABLE_AUTO_SAVE_PLAN',
  CREATE_AUTO_SAVE_PLAN: 'savings/CREATE_AUTO_SAVE_PLAN',
  CREATE_QUICK_SAVE: 'savings/CREATE_QUICK_SAVE',
  USER_SAVINGS_TRANSACTION: 'savings/USER_SAVINGS_TRANSACTION',
  GET_AUTO_SAVE_PLAN_DETAILS: 'savings/GET_AUTO_SAVE_PLAN_DETAILS',
  SAVINGS_WITHDRAWAL: 'savings/SAVINGS_WITHDRAWAL',
  SAVINGS_WITHDRAWAL_TO_BANK: 'savings/SAVINGS_WITHDRAWAL_TO_BANK',
  SAVINGS_WITHDRAWAL_ELIGIBILITY: 'savings/SAVINGS_WITHDRAWAL_ELIGIBILITY',
  HANDLE_OTP_MODAL: 'savings/HANDLE_OTP_MODAL',
  HANDLE_SECTION: 'savings/HANDLE_SECTION',
}

export default actions
