import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
    userDashboard: jwt.userDashboard,
}

export function* GET_USER_DASHBOARD() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.userDashboard)
  if (success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
        data: success.data,
        transactions: success.data.latest_transactions
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_DASHBOARD, GET_USER_DASHBOARD),
  ])
}
