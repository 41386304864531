import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import dashboard from './dashboard/sagas'
import profile from './profile/sagas'
import wallet from './wallet/sagas'
import savings from './savings/sagas'
import target from './target/sagas'
import loan from './loan/sagas'
import share from './share/sagas'
import procurement from './procurement/sagas'

export default function* rootSaga() {
  yield all([
    user(), 
    menu(), 
    settings(), 
    dashboard(), 
    profile(),
    wallet(),
    savings(),
    target(),
    loan(),
    share(),
    procurement(),
  ])
}
