// eslint-disable-next-line no-unused-vars
import Foot from 'pages/landing/foooter'
import Navbar from 'pages/landing/navbar'
/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = ({profile, user}) => ({
  data: {...profile.data, ...user.localData}
})
const PublicLayout = ({ children, data }) => {
  return (
    <div>
      <Navbar profileData={data} />
      <div className='pt-70p'>
        {children}
      </div>
      <Foot />
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(PublicLayout))
