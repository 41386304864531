import { Card, Modal } from 'antd'
/* eslint-disable camelcase */
import React from 'react'
import { history } from 'index'
import ActionButton from 'pages/profile/shared/actionButton'

function TodoModal({
  visible, 
  closeModal,
  pStatus,
  mStatus,
  mtStatus,
  loading,
  dispatch,
}) {
  const todoList = [
    {
      id: 1,
      title: <ActionButton title="Update Profile" img="/resources/images/icons/profile.png" visible /> ,
      visible: pStatus,
      task: "profile",
      url: '/settings'
    },
    {
      id: 2,
      title: <ActionButton title="Pay Membership Fee" img="/resources/images/icons/membership.png" visible />,
      visible: mStatus,
      task: "membership",
      url: '/settings'
    },
    {
      id: 3,
      title: <ActionButton title="Switch Membership" img="/resources/images/icons/suit.png" visible /> ,
      visible: mtStatus !== 2,
      task: "switch",
      url: '/settings'
    },
  ]

  const handleClick = (url, payload) => {
    closeModal(); 
    if(url && url.length){
      history.push(url)
    }
    dispatch({type: "profile/HANDLE_FOCUSED_TASK", payload})
  }
  return (
    <Modal
      title={`${mtStatus === 2?"Possible Hint":"Todo List"}`}
      visible={visible}
      footer={false}
      onCancel={closeModal}
      bodyStyle={{padding: 0}}
    >
      <Card loading={loading} bodyStyle={{padding: 0}} className='border-0 p-0 p-md-5 m-0 pb-4'>
        {
          todoList.map((e) => (
            <button type='button' onClick={()=> handleClick(e.url, e.task)} key={e.id} className={`w-100 border-0 bg-transparent d-${!e.visible?'block' : 'none'}`}>
              {e.title}
            </button>
          ))
        }
      </Card>
    </Modal>
  )
}

export default TodoModal