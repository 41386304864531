import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Divider } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ dispatch }) => ({ dispatch })

const ProfileMenu = ({ dispatch, profileImage, fullName, email, phone, mId }) => {
  const [count, setCount] = useState(7)

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/HANDLE_LOGOUT_MODAL',
      payload: true
    })
  }
  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {phone || '—'}
        </div>
      </Menu.Item>
      {!mId?.includes("ZSP") && (
        <>
          <Divider className="p-0 my-2" />
          <Menu.Item>
            <Link to="/dashboard" className="text-dark px-3">
              <i className="fe fe-home mr-2" />
              <FormattedMessage id="topBar.profileMenu.dashboard" />
            </Link>
          </Menu.Item>
          <Divider className="p-0 my-2" />
          <Menu.Item>
            <Link to="/settings" className="text-dark px-3" onClick={()=> dispatch({type: "profile/HANDLE_FOCUSED_TASK", payload: "profile"})}>
              <i className="fe fe-user mr-2" />
              <FormattedMessage id="topBar.profileMenu.editProfile" />
            </Link>
          </Menu.Item>
          <Divider className="p-0 my-2" />
          <Menu.Item>
            <a href="#" onClick={logout} className="text-dark px-3">
              <i className="fe fe-log-out mr-2" />
              <FormattedMessage id="topBar.profileMenu.logout" />
            </a>
          </Menu.Item>
        </>
      )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} alt="zero user" src={profileImage} shape="circle" size="large" icon={<UserOutlined />} />
        <b className='d-none d-md-inline mx-3 text-dark text-capitalize'>{fullName}</b>
        {/* <Badge count={count}>
        </Badge> */}
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
