/* eslint-disable camelcase */
import React from 'react'
import { Alert, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import Footer from 'components/cleanui/layout/Footer'
// import Sidebar from 'components/cleanui/layout/Sidebar'
import TodoModal from 'components/kit/widgets/Modals/todo'
// import SupportChat from 'components/cleanui/layout/SupportChat'

const mapStateToProps = ({ dispatch, user, profile, settings }) => ({
  dispatch,
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  userStatus: user.userStatus,
  loading: user.loading,
  profileLoading: profile.loading,
  profileData: profile.data,
  todoModal: user.todoModal,
  data: profile.data,
  inputData: profile.inputData,
  membershipFee: user.membershipFee,
  fileUploadUrl: user.fileUploadUrl
})

const MainLayout = ({
  children,
  dispatch,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  userStatus,
  profileData,
  loading,
  todoModal,
  data,
  inputData,
  membershipFee,
  fileUploadUrl,
  profileLoading
}) => {

  React.useEffect(()=> {
    dispatch({type: 'user/GET_USER_STATUS'})
    dispatch({type: 'profile/GET_USER_PROFILE'})
    dispatch({type: "user/GET_MEMBERSHIP_FEE"})
  },[dispatch])

  const {
    // membership_type: mType,
    membership_type_status: mtStatus,
    profile_status : pStatus,
    member_status : mStatus
  } = userStatus && userStatus

  const setTodoModal = (val) => {
    dispatch({
      type: 'user/TODO_MODAL',
      payload: val
    })
  }
  // !(mType?.includes("non")) && 
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Alert 
        className={`text-center d-${
          (!mtStatus || mtStatus === 2) && !loading ? 'flex' : "none"}`}
        message={<span><b>Status</b>: account {mtStatus === 2?'declined, please check your mail for more information':'awaiting admin approval'}.</span>}
        type={mtStatus === 2 ? "error" : "info"}
        showIcon
      />
      <TodoModal
        visible={todoModal}
        closeModal={()=> setTodoModal(false)}
        pStatus={pStatus}
        mStatus={mStatus}
        mtStatus={mtStatus}
        loading={loading || profileLoading}
        data={data}
        inputData={inputData}
        amount={membershipFee}
        dispatch={dispatch}
        fileUploadUrl={fileUploadUrl}
      />
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar data={profileData} />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="cui__utils__content">{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
