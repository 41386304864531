/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'procurement/SET_STATE',
  GET_USER_PROCUREMENT: 'procurement/GET_USER_PROCUREMENT',
  GET_PROCUREMENT_FORM_FEE: 'procurement/GET_PROCUREMENT_FORM_FEE',
  PAY_PROCUREMENT_FORM_FEE: 'procurement/PAY_PROCUREMENT_FORM_FEE',
  REQUEST_FOR_PROCUREMENT: 'procurement/REQUEST_FOR_PROCUREMENT',
  EDIT_PROCUREMENT_REQUEST: 'procurement/EDIT_PROCUREMENT_REQUEST',
  CANCEL_PROCUREMENT: 'procurement/CANCEL_PROCUREMENT',
  GET_ALL_PROCUREMENTS: 'procurement/GET_ALL_PROCUREMENTS',
  GET_PROCUREMENT_WAIVER: 'procurement/GET_PROCUREMENT_WAIVER',
  GET_SINGLE_PROCUREMENT_DETAIL: 'procurement/GET_SINGLE_PROCUREMENT_DETAIL',
  GET_EDIT_PROCUREMENT_DETAIL: 'procurement/GET_EDIT_PROCUREMENT_DETAIL',
  PAY_PROCUREMENT_REPAYMENT: 'procurement/PAY_PROCUREMENT_REPAYMENT',
  GET_REPAYMENT_DURATION: 'procurement/GET_REPAYMENT_DURATION',
  GET_USER_AUTOCOMPLETE: 'procurement/GET_USER_AUTOCOMPLETE',
  HANDLE_SECTION: 'procurement/HANDLE_SECTION',

  // special procurement section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  HANDLE_SPECIAL_SECTION: 'procurement/HANDLE_SPECIAL_SECTION',
  GUARANTOR_ACCEPT_DECLAIN: 'procurement/GUARANTOR_ACCEPT_DECLAIN',
  HANDLE_SPECIAL_LOGIN_MODAL: 'procurement/HANDLE_SPECIAL_LOGIN_MODAL',
  REQUEST_FOR_SPECIAL_PROCUREMENT: 'procurement/REQUEST_FOR_SPECIAL_PROCUREMENT',
  GET_GUARANTORS_STATUS: 'procurement/GET_GUARANTORS_STATUS',
  GET_PROCURE_DETAILS: 'procurement/GET_PROCURE_DETAILS',
  GET_GUARANTOR_STATUS: 'procurement/GET_GUARANTOR_STATUS',
}

export default actions
