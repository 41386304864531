import React from 'react'
import { Card, Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import { formatCurrency } from 'services/axios'
import style from './style.module.scss'

const { TabPane } = Tabs

const List2 = ({guarantor}) => {
  const history = useHistory()
  return (
    <div>
      <Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1">
        <TabPane tab="Loan Guarantee Requests" key="1">
          <div className="text-gray-6">
            <ul className="list-unstyled">
              {guarantor.map(d=>(
                <Card onClick={()=>history.push('/loan')} style={{cursor: 'pointer'}}>
                  <li key={d.loan_id} className="mb-3">
                    <div className={style.head}>
                      <p className={style.title}>
                        Loan Applicant:
                        <strong className="text-black">{d.first_name} {d.last_name}</strong>
                      </p>
                      {/* <time className={style.time}>5 min ago</time> */}
                    </div>
                    <div className={style.head}>
                      <p className={style.title}>
                        Loan Amount:
                        <strong className="text-black">&#8358; {formatCurrency(Number(d.loan_amount))}.00</strong>
                      </p>
                      {/* <time className={style.time}>5 min ago</time> */}
                    </div>
                    <div className={style.head}>
                      <p className={style.title}>
                        Guarantee Amount:
                        <strong className="text-black">&#8358; {formatCurrency(Number(d.guaranteed_amount))}.00</strong>
                      </p>
                      {/* <time className={style.time}>5 min ago</time> */}
                    </div>
                    {/* <p>Mary has approved your quote.</p> */}
                  </li>
                </Card>
              ))}
            </ul>
          </div>
        </TabPane>
        {/* <TabPane tab="Events" key="2">
          <div className="text-center mb-3 py-4 bg-light rounded">No Events</div>
        </TabPane>
        <TabPane tab="Actions" key="3">
          <div className="text-center mb-3 py-4 bg-light rounded">No Actions</div>
        </TabPane> */}
      </Tabs>
    </div>
  )
}

export default List2
