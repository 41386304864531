/* eslint-disable camelcase */
import actions from './actions'

const initialState = {
  loading: false,
  focusedTask: "",
  data: {},
  inputData: {},
  bankList: [],
  getAccountLoader: false,
  bankAccount: {},
  acctAll:{},
  bankDetailsEditable: false,
  savingsAccountStatement: [],
  loanAccountStatement: [],
  procurementAccountStatement: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
