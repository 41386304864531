/* eslint-disable camelcase */
import actions from './actions'

const initialState = {
  data: {},
  summary: [],
  loading: false,
  pageSection: "home",
  targetPlanLists: [],
  targetPlanDetails: {},
  ongoingPlans: [],
  completedPlans: [],
  singlePlanDetails: {},
  targetFormFee: 2500,
  singlePlanTransactions: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
