/* eslint-disable camelcase */
import store from 'store'
import actions from './actions'

const initialState = {
  data: {},
  procurementSummary: [],
  editProcurementDetail: {},
  loading: false,
  searchLoading: false,
  pageSection: "home",
  procurementFormFee: 0,
  pendingProcurement: [],
  ongoingProcurement: [],
  completedProcurement: [],
  cancelledProcurement: [],
  singleProcurementSummary: {},
  singleProcurementTransaction: [],
  singleProcurementGuarantor: {members: [], non_members: []},
  repaymentDuration: [],
  userAutocomplete: [],
  waiverData: { duration_waiver: 0, waiver: 0 },
  
  guaranteedStatus:[],
  guaranteedDetails:{},
  pendingGuarantorLoan: [],
  ongoingGuarantorLoan: [],
  cancelledGuarantorLoan: [],

  // special procurement section >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  specialLoading: false,
  specialSection: store.get("procurement_section") || "home",
  specialLoginModal: false,
  specialSubmittedData: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
