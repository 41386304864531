/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'share/SET_STATE',
  GET_USER_SHARE: 'share/GET_USER_SHARE',
  FUND_USER_SHARE: 'share/FUND_USER_SHARE',
  WITHDRAW_USER_SHARE: 'share/WITHDRAW_USER_SHARE',
  WITHDRAW_USER_SHARE_TO_BANK: 'share/WITHDRAW_USER_SHARE_TO_BANK',
  GET_SHARE_PAYMENT_METHOD: 'share/GET_SHARE_PAYMENT_METHOD',
}

export default actions
