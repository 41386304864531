/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable camelcase */
// import store from 'store'
// import FavPages from './FavPages'
// import Search from './Search'
// import IssuesHistory from './IssuesHistory'
// import ProjectManagement from './ProjectManagement'
// import LanguageSwitcher from './LanguageSwitcher'
// /* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Actions from './Actions'
// import { connect } from 'react-redux'
import UserMenu from './UserMenu'
import style from './style.module.scss'


const hours = new Date().getHours()
// const mins = new Date().getMinutes()
// const { name, profile_pic: profile, email, phone_no: phone } = store.get('zero-user') || {}
// const img = "/resources/images/icons/clock.png"
const mapStateToProps = ({ dispatch, loan }) => ({
  dispatch,
  pendingGuarantorLoan: loan.pendingGuarantorLoan,
  // ongoingGuarantorLoan: loan.ongoingGuarantorLoan,
  // cancelledGuarantorLoan: loan.cancelledGuarantorLoan,
})

const TopBar = ({
  data,
  pendingGuarantorLoan,
  dispatch
}) => {


  useEffect(()=>{
    dispatch({type: 'loan/GET_GUARANTORS_STATUS'})
  },[dispatch])

  const {
    profile_pic: img,
    first_name: fName,
    last_name: lName,
    phone_no: mobile,
    email,
    member_id: mId,
  } = data && data


  return (
    <div className={style.topbar}>
      <div className="mr-4">
        <div className="pt-2 text-secondary d-flex">
          <div className='ms-3'>
            <strong className='text-dark text-capitalize'><span className='d-none d-md-inline'>Good { hours < 12 ? 'morning' : hours < 16 ? 'afternoon' : 'evening' }</span> {fName} {lName}</strong>
          </div>
        </div>
        {/* <FavPages /> */}
      </div>
      <div className="mr-auto">
        {/* <Search /> */}
      </div>
      <div className="mr-4 d-none d-md-block">
        {/* <IssuesHistory /> */}
      </div>
      <div className="mb-0 mr-auto d-xl-block d-none">
        {/* <ProjectManagement /> */}
      </div>
      <div className="mr-4 d-none d-sm-block">
        {/* <LanguageSwitcher /> */}
      </div>
      <div className="mr-4 d-none d-sm-block">
        <Actions bell={pendingGuarantorLoan.length} guarantor={pendingGuarantorLoan} />
      </div>
      <div className="">
        <UserMenu
          profileImage={img}
          mId={mId}
          email={email}
          phone={mobile}
          fullName={fName ? `${fName} ${lName}` : "user"}
        />
      </div>
    </div>
  )
}

// export default TopBar
export default connect(mapStateToProps)(TopBar)
