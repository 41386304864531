import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
  getTargetData: jwt.getTargetData,
  getTargetPlans: jwt.getTargetPlans,
  getTargetPlanList: jwt.getTargetPlanList,
  disableAutoSavePlan: jwt.disableAutoSavePlan,
  createTargetPlan: jwt.createTargetPlan,
  stopTargetPlan: jwt.stopTargetPlan,
  getTargetPlanDetails: jwt.getTargetPlanDetails,
  getSingleTargetPlanDetails: jwt.getSingleTargetPlanDetails,
  targetQuickSave: jwt.targetQuickSave,
  getTargetFormFee: jwt.getTargetFormFee,
  payTargetFormFee: jwt.payTargetFormFee,
  targetWithdrawal: jwt.targetWithdrawal,
  targetBankWithdrawal: jwt.targetBankWithdrawal,
}

export function* GET_TARGET_SAVINGS() {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetData)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        data: success.data,
        summary: success.data.active_target_summary
      }
    })
    yield put({type: "target/GET_TARGET_SAVINGS_PLANS"})
    yield put({type: 'target/GET_TARGET_SAVINGS_PLAN_LISTS'})
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_TARGET_SAVINGS_PLANS() {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetPlans)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        ongoingPlans: success?.data?.ongoing,
        completedPlans: success?.data?.completed,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_TARGET_SAVINGS_PLAN_LISTS() {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetPlanList)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        targetPlanLists: success.data,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DISABLE_AUTO_SAVE_PLAN() {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.disableAutoSavePlan)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'target/GET_TARGET_SAVINGS'})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* CREATE_TARGET_PLAN({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createTargetPlan, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "target/GET_TARGET_SAVINGS"})
    yield put({type: "target/HANDLE_SECTION", payload: "home"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* STOP_TARGET_PLAN({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.stopTargetPlan, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "target/GET_TARGET_SAVINGS"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_TARGET_PLAN_DETAILS({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetPlanDetails, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        targetPlanDetails: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_SINGLE_TARGET_PLAN_DETAILS({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSingleTargetPlanDetails, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        singlePlanDetails: {...success?.data?.plan_summary[0], show_withdraw_button: success?.data?.show_withdraw_button, withdrawal_balance: success?.data?.withdrawal_balance},
        singlePlanTransactions: success?.data?.trans
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* CREATE_TARGET_QUICK_SAVE({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.targetQuickSave, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      }
    })
    notification.success({
      message: "Successful!",
      description: success.message
    })
    yield put({type: "target/GET_TARGET_SAVINGS"})
    yield put({
      type: "target/GET_SINGLE_TARGET_PLAN_DETAILS",
      payload: payload.id
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* TARGET_SAVINGS_WITHDRAWAL({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.targetWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: true
    })
    yield put({type: "target/GET_TARGET_SAVINGS"})
    yield put({
      type: "target/GET_SINGLE_TARGET_PLAN_DETAILS",
      payload: payload.target_id
    })
    notification.success({
      message: "Request Sent!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* TARGET_SAVINGS_WITHDRAWAL_TO_BANK({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const {withdrawal_pin: pin, id} = payload
  const success = yield call(jwts.targetBankWithdrawal, {withdrawal_pin: pin})
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        withdrawalPinModal: false
      }
    })
    yield put({type: "target/GET_TARGET_SAVINGS"})
    yield put({type: "target/GET_SINGLE_TARGET_PLAN_DETAILS", payload: id})
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: false
    })
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}


export function* GET_TARGET_FEE() {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetFormFee)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        targetFormFee: success.data || 2500,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAY_TARGET_FEE({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payTargetFormFee, payload?.pay)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        targetFormFee: success.data,
      }
    })
    yield put({
      type: "target/CREATE_TARGET_PLAN",
      payload: payload?.create
    })
    notification.success({
      message: "Success",
      description: success?.message || "payment successful!"
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error",
      description: success?.message || "payment failed!"
    })
  }
}

export function* HANDLE_SECTION({payload}) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      pageSection: payload
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TARGET_SAVINGS, GET_TARGET_SAVINGS),
    takeEvery(actions.GET_TARGET_SAVINGS_PLANS, GET_TARGET_SAVINGS_PLANS),
    takeEvery(actions.GET_TARGET_SAVINGS_PLAN_LISTS, GET_TARGET_SAVINGS_PLAN_LISTS),
    takeEvery(actions.DISABLE_AUTO_SAVE_PLAN, DISABLE_AUTO_SAVE_PLAN),
    takeEvery(actions.CREATE_TARGET_PLAN, CREATE_TARGET_PLAN),
    takeEvery(actions.STOP_TARGET_PLAN, STOP_TARGET_PLAN),
    takeEvery(actions.GET_TARGET_PLAN_DETAILS, GET_TARGET_PLAN_DETAILS),
    takeEvery(actions.GET_SINGLE_TARGET_PLAN_DETAILS, GET_SINGLE_TARGET_PLAN_DETAILS),
    takeEvery(actions.CREATE_TARGET_QUICK_SAVE, CREATE_TARGET_QUICK_SAVE),
    takeEvery(actions.TARGET_SAVINGS_WITHDRAWAL, TARGET_SAVINGS_WITHDRAWAL),
    takeEvery(actions.GET_TARGET_FEE, GET_TARGET_FEE),
    takeEvery(actions.PAY_TARGET_FEE, PAY_TARGET_FEE),
    takeEvery(actions.TARGET_SAVINGS_WITHDRAWAL_TO_BANK, TARGET_SAVINGS_WITHDRAWAL_TO_BANK),
    takeEvery(actions.HANDLE_SECTION, HANDLE_SECTION),
  ])
}
