import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Home >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // {
  //   path: '/',
  //   Component: lazy(() => import('pages/home')),
  //   exact: true,
  // },
  {
    path: '/',
    Component: lazy(() => import('pages/landing')),
    exact: true,
  },

  // main pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // Dashboards >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/savings',
    Component: lazy(() => import('pages/savings')),
    exact: true,
  },
  {
    path: '/target-savings',
    Component: lazy(() => import('pages/target')),
    exact: true,
  },
  {
    path: '/loan',
    Component: lazy(() => import('pages/loan')),
    exact: true,
  },
  {
    path: '/procurement',
    Component: lazy(() => import('pages/procurement')),
    exact: true,
  },
  {
    path: '/wallet',
    Component: lazy(() => import('pages/wallet')),
    exact: true,
  },
  {
    path: '/shares',
    Component: lazy(() => import('pages/shares')),
    exact: true,
  },
  {
    path: '/transactions',
    Component: lazy(() => import('pages/transactions')),
    exact: true,
  },
  {
    path: '/page/whatwedo',
    Component: lazy(() => import('pages/whatwedo')),
    exact: true,
  },
  {
    path: '/page/aboutus',
    Component: lazy(() => import('pages/aboutus')),
    exact: true,
  },
  {
    path: '/page/membership',
    Component: lazy(() => import('pages/membership')),
    exact: true,
  },
  {
    path: '/page/contactus',
    Component: lazy(() => import('pages/contactus')),
    exact: true,
  },
  {
    path: '/page/procurement',
    Component: lazy(() => import('pages/procurement/public')),
    exact: true,
  },
  {
    path: '/investment',
    Component: lazy(() => import('pages/investment')),
    exact: true,
  },
  {
    path: '/page/terms-and-policy',
    Component: lazy(() => import('pages/terms')),
    exact: true,
  },
  // {
  //   path: '/settings',
  //   Component: lazy(() => import('pages/settings')),
  //   exact: true,
  // },
  {
    path: '/settings',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },

  // Auth Pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: '/auth/login/',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/recovery',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/verify-email/:id',
    Component: lazy(() => import('pages/auth/verify-email')),
    exact: true,
  },
  {
    path: '/auth/reset-password/:id/:email',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/register/screen/:email/:id',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/zero-pay',
    Component: lazy(() => import('pages/auth/zero-pay')),
    exact: true,
  },
  {
    path: '/auth/zero-pay-playground',
    Component: lazy(() => import('pages/auth/zero-pay/playGround')),
    exact: true,
  }
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} /> */}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
