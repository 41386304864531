import store from 'store'
/* eslint-disable camelcase */
import actions from './actions'

const localData = store.get('zero-user') || {}
const { member_id: memberId, last_login: lastLogin } = localData

const initialState = {
  id: '',
  name: '',
  email: '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  userStatus: {
    membership_type: '',
    membership_type_status: 1,
    profile_status: 1,
    member_status: 1,
  },
  token: '',
  loading: false,
  todoModal: false,
  logoutModal: false,
  refundModal: false,
  disclaimerModal: false,
  lastLogin: lastLogin || '00 : 00',
  membershipId: memberId || '',
  localData,
  userCards: [],
  userBankDetails: {},
  transactionCategory: [],
  userTransactions: [],
  organizationList: [],
  membershipFee: 0,
  fileUploadUrl: '',
  recoveryValue: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
