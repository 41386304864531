/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'loan/SET_STATE',
  GET_USER_LOAN: 'loan/GET_USER_LOAN',
  GET_USER_LOAN_STATUS: 'loan/GET_USER_LOAN_STATUS',
  GET_USER_LOAN_ELIGIBILITY: 'loan/GET_USER_LOAN_ELIGIBILITY',
  GET_LOAN_FORM_FEE: 'loan/GET_LOAN_FORM_FEE',
  GET_AVAILABLE_SAVINGS_BALANCE: 'loan/GET_AVAILABLE_SAVINGS_BALANCE',
  LOAN_CALCULATOR: 'loan/LOAN_CALCULATOR',
  PAY_LOAN_FORM_FEE: 'loan/PAY_LOAN_FORM_FEE',
  REQUEST_FOR_LOAN: 'loan/REQUEST_FOR_LOAN',
  CANCEL_LOAN_REQUEST: 'loan/CANCEL_LOAN_REQUEST',
  GET_ALL_LOANS: 'loan/GET_ALL_LOANS',
  GET_ALL_LOAN_DETAILS: 'loan/GET_ALL_LOAN_DETAILS',
  EDIT_LOAN_DETAILS: 'loan/EDIT_LOAN_DETAILS',
  GET_LOAN_DETAILS: 'loan/GET_LOAN_DETAILS',
  PAY_LOAN_REPAYMENT: 'loan/PAY_LOAN_REPAYMENT',
  GET_USER_AUTOCOMPLETE: 'loan/GET_USER_AUTOCOMPLETE',
  GET_REPAYMENT_DURATION: 'loan/GET_REPAYMENT_DURATION',
  GUARANTOR_ACCEPT_DECLAIN: 'loan/GUARANTOR_ACCEPT_DECLAIN',
  GET_GUARANTOR_STATUS: 'loan/GET_GUARANTOR_STATUS',
  HANDLE_SECTION: 'loan/HANDLE_SECTION',
  GET_GUARANTORS_STATUS: 'loan/GET_GUARANTORS_STATUS',
}

export default actions
