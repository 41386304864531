/* eslint-disable no-restricted-globals */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
import { history } from 'index'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'
// import { CodeFilled } from '@ant-design/icons'

const jwts = {
  login: jwt.login,
  register: jwt.register,
  resendEmail: jwt.resendEmail,
  forgotPassword: jwt.forgotPassword,
  resetPassword: jwt.resetPassword,
  verifyEmail: jwt.verifyEmail,
  getUserStatus: jwt.getUserStatus,
  switchMembership: jwt.switchMembership,
  getNewToken: jwt.getNewToken,
  getUserCards: jwt.getUserCards,
  getUserBankDetails: jwt.getUserBankDetails,
  getTransactionCategory: jwt.getTransactionCategory,
  getUserTransaction: jwt.getUserTransaction,
  getMembershipFee: jwt.getMembershipFee,
  payMembershipFee: jwt.payMembershipFee,
  getFileUploadURL: jwt.getFileUploadURL,
  createSecurityOTP: jwt.createSecurityOTP,
  verifySecurityOTP: jwt.verifySecurityOTP,
  recoverySecurityOTP: jwt.recoverySecurityOTP,
  getAllOrganizations: jwt.getAllOrganizations,
  requestRefund: jwt.requestRefund,
  accountClosure: jwt.accountClosure,
  initializeZeroPay: jwt.initializeZeroPay,
}
// const { authProvider: autProviderName } = yield select(state => state.settings)

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.login, payload)
  if (success.status) {
    const {
      user_id: id,
      name,
      email,
      profile_pic: avatar,
      message,
      membership_type: mType,
      member_id: mId,
    } = success
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        authorized: true,
        loading: false,
        lastLogin: success.last_login || '',
        membershipId: success.member_id || '',
        localData: success,
      },
    })
    store.set('zero-user', success)
    if (mType?.includes('non member') || mId?.includes('ZSP')) {
      yield put({ type: 'procurement/HANDLE_SPECIAL_LOGIN_MODAL', payload: false })
      yield put({ type: 'procurement/HANDLE_SPECIAL_SECTION', payload: 'details' })
      yield history.push('/page/procurement')
    } else {
      yield history.push('/dashboard')
    }
    notification.success({
      message: 'Logged In',
      description: message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Log In Error',
      description: success.message,
    })
  }
}

export function* FORGOT_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.forgotPassword, payload)
  if (success.status) {
    store.set('recovery-email', payload)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        recoveryValue: payload,
      },
    })
    if (!isNaN(payload.email)) {
      yield put({
        type: 'savings/HANDLE_OTP_MODAL',
        payload: true,
      })
    }
    notification.success({
      message: 'Successful!',
      description: success.message || success.data.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error',
      description: success.message || success.data.message,
    })
  }
}

export function* VERIFY_EMAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.verifyEmail, payload)
  const { message } = success
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push('/auth/login')
    notification.success({
      message: 'Successful!',
      description: message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error',
      description: message,
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.resetPassword, payload)
  const { user_id: id, email, message } = success
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        email,
        authorized: true,
        loading: false,
        recoveryValue: {},
      },
    })
    yield history.push('/auth/login')
    store.remove('recovery-email')
    notification.success({
      message: 'Successful',
      description: message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error',
      description: message,
    })
  }
}

export function* REGISTER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.register, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push(`/auth/register/screen/${success.email}/${success.user_id}`)
    notification.success({
      message: 'Success!',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* RESEND_EMAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.resendEmail, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Request Sent Successfully',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Request unSuccessful',
      description: success.message,
    })
  }
}

export function* GET_USER_STATUS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserStatus)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userStatus: success.data,
      },
    })
    if (!success.data.profile_status || !success.data.member_status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          todoModal: true,
        },
      })
    }
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SWITCH_MEMBERSHIP_TYPE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.switchMembership, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'user/GET_USER_STATUS' })
    notification.success({
      message: 'Membership Type Updated',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Membership Type Not Updated',
      description: success.message,
    })
  }
}

export function* GET_TOKEN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getNewToken)
  if (success.length) {
    const { token } = success[0]
    store.set('accessToken', token)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        token,
      },
    })
  }
  if (!success.length) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_USER_CARDS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserCards)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userCards: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_USER_BANK_DETAILS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserBankDetails)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userBankDetails: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    // notification.error({
    //   message: "Error!",
    //   description: success.message
    // })
  }
}

export function* GET_TRANSACTION_CATEGORY() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTransactionCategory)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        transactionCategory: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_USER_TRANSACTIONS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserTransaction, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userTransactions: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_MEMBERSHIP_FEE() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getMembershipFee)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        membershipFee: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAY_MEMBERSHIP_FEE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payMembershipFee, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'user/GET_USER_STATUS' })
    yield put({ type: 'user/GET_USER_CARDS' })
    yield put({ type: 'profile/GET_USER_PROFILE' })
    yield put({ type: 'profile/GET_PROFILE_INPUTS' })
    notification.success({
      message: 'Successful!',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_FILE_UPLOAD_URL({ payload }) {
  const maxSize = 2000000
  const fileSize = payload?.get("image_file")?.size
  const isFileSize = (fileSize <= maxSize)
  if (isFileSize) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.getFileUploadURL, payload)
    if (success.status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          fileUploadUrl: success.image_path,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  } else return notification.error({
    message: "Error!",
    description: "file size exceeds 2MB, please choose lesser."
  })
}

export function* CREATE_SECURITY_OTP() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createSecurityOTP)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: true,
    })
    notification.success({
      // message: "Successful!",
      description: success.message,
      duration: 5,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* VERIFY_SECURITY_OTP({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.verifySecurityOTP, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        bankDetailsEditable: true,
      },
    })
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: false,
    })
    notification.success({
      message: 'Verification Successful!',
      description: `${success.message} you can now proceed`,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        bankDetailsEditable: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* RECOVERY_SECURITY_OTP({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.recoverySecurityOTP, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: false,
    })
    yield history.push(`/auth/reset-password/${payload?.token}`)
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ALL_ORGANIZATIONS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllOrganizations)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        organizationList: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* REQUEST_REFUND({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.requestRefund, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'user/HANDLE_REFUND_MODAL', payload: false })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* ACCOUNT_CLOSURE() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.accountClosure)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield put({ type: 'user/LOGOUT', payload: true })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* INITIALIZE_ZERO_PAY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.initializeZeroPay, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Success!',
      description: success.message,
    })
    if (success?.url && success?.url?.includes('http')) {
      window.location.href = success?.url
    }
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* HANDLE_LOGOUT_MODAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      logoutModal: payload,
    },
  })
}

export function* HANDLE_REFUND_MODAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      refundModal: payload,
    },
  })
}

export function* LOGOUT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      email: '',
      avatar: '',
      authorized: false,
      userStatus: {},
      localData: {},
      loading: false,
    },
  })
  store.clearAll()
  yield put({ type: 'procurement/HANDLE_SPECIAL_LOGIN_MODAL', payload: false })
  yield put({ type: 'procurement/HANDLE_SPECIAL_SECTION', payload: 'home' })
  notification.success({
    message: 'Logged Out',
    description: payload
      ? 'your account has been closed and request sent!'
      : 'hope to see you soon.',
  })
  history.push('/')
}

export function* TODO_MODAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      todoModal: payload,
    },
  })
}

export function* DISCLAIMER_MODAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      disclaimerModal: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.RESEND_EMAIL, RESEND_EMAIL),
    takeEvery(actions.HANDLE_LOGOUT_MODAL, HANDLE_LOGOUT_MODAL),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.VERIFY_EMAIL, VERIFY_EMAIL),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.GET_USER_STATUS, GET_USER_STATUS),
    takeEvery(actions.SWITCH_MEMBERSHIP_TYPE, SWITCH_MEMBERSHIP_TYPE),
    takeEvery(actions.TODO_MODAL, TODO_MODAL),
    takeEvery(actions.GET_TOKEN, GET_TOKEN),
    takeEvery(actions.GET_USER_CARDS, GET_USER_CARDS),
    takeEvery(actions.GET_USER_BANK_DETAILS, GET_USER_BANK_DETAILS),
    takeEvery(actions.GET_TRANSACTION_CATEGORY, GET_TRANSACTION_CATEGORY),
    takeEvery(actions.GET_USER_TRANSACTIONS, GET_USER_TRANSACTIONS),
    takeEvery(actions.GET_MEMBERSHIP_FEE, GET_MEMBERSHIP_FEE),
    takeEvery(actions.PAY_MEMBERSHIP_FEE, PAY_MEMBERSHIP_FEE),
    takeEvery(actions.GET_FILE_UPLOAD_URL, GET_FILE_UPLOAD_URL),
    takeEvery(actions.CREATE_SECURITY_OTP, CREATE_SECURITY_OTP),
    takeEvery(actions.VERIFY_SECURITY_OTP, VERIFY_SECURITY_OTP),
    takeEvery(actions.RECOVERY_SECURITY_OTP, RECOVERY_SECURITY_OTP),
    takeEvery(actions.GET_ALL_ORGANIZATIONS, GET_ALL_ORGANIZATIONS),
    takeEvery(actions.HANDLE_REFUND_MODAL, HANDLE_REFUND_MODAL),
    takeEvery(actions.REQUEST_REFUND, REQUEST_REFUND),
    takeEvery(actions.ACCOUNT_CLOSURE, ACCOUNT_CLOSURE),
    takeEvery(actions.INITIALIZE_ZERO_PAY, INITIALIZE_ZERO_PAY),
    takeEvery(actions.DISCLAIMER_MODAL, DISCLAIMER_MODAL),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
