/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  RESEND_EMAIL: 'user/RESEND_EMAIL',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  HANDLE_LOGOUT_MODAL: 'user/HANDLE_LOGOUT_MODAL',
  LOGOUT: 'user/LOGOUT',
  VERIFY_EMAIL: 'user/VERIFY_EMAIL',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  GET_USER_STATUS: 'user/GET_USER_STATUS',
  SWITCH_MEMBERSHIP_TYPE: 'user/SWITCH_MEMBERSHIP_TYPE',
  TODO_MODAL: 'user/TODO_MODAL',
  GET_TOKEN: 'user/GET_TOKEN',
  GET_USER_CARDS: 'user/GET_USER_CARDS',
  GET_USER_BANK_DETAILS: 'user/GET_USER_BANK_DETAILS',
  GET_USER_TRANSACTIONS: 'user/GET_USER_TRANSACTIONS',
  GET_TRANSACTION_CATEGORY: 'user/GET_TRANSACTION_CATEGORY',
  GET_MEMBERSHIP_FEE: 'user/GET_MEMBERSHIP_FEE',
  PAY_MEMBERSHIP_FEE: 'user/PAY_MEMBERSHIP_FEE',
  GET_FILE_UPLOAD_URL: 'user/GET_FILE_UPLOAD_URL',
  CREATE_SECURITY_OTP: 'user/CREATE_SECURITY_OTP',
  VERIFY_SECURITY_OTP: 'user/VERIFY_SECURITY_OTP',
  RECOVERY_SECURITY_OTP: 'user/RECOVERY_SECURITY_OTP',
  GET_ALL_ORGANIZATIONS: 'user/GET_ALL_ORGANIZATIONS',
  HANDLE_REFUND_MODAL: 'user/HANDLE_REFUND_MODAL',
  REQUEST_REFUND: 'user/REQUEST_REFUND',
  ACCOUNT_CLOSURE: 'user/ACCOUNT_CLOSURE',
  INITIALIZE_ZERO_PAY: 'user/INITIALIZE_ZERO_PAY',
  DISCLAIMER_MODAL: 'user/DISCLAIMER_MODAL',
}

export default actions
