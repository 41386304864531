import { message, notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
    getLoanDashboard: jwt.getLoanDashboard,
    getLoanStatus: jwt.getLoanStatus,
    getLoanEligibility: jwt.getLoanEligibility,
    getLoanFormFee: jwt.getLoanFormFee,
    getAvailableSavingsBalance: jwt.getAvailableSavingsBalance,
    getLoanCalculator: jwt.getLoanCalculator,
    payLoanFormFee: jwt.payLoanFormFee,
    requestForLoan: jwt.requestForLoan,
    cancelLoanRequest: jwt.cancelLoanRequest,
    getAllLoans: jwt.getAllLoans,
    getAllLoanDetails: jwt.getAllLoanDetails,
    getGuarantorStatus: jwt.getGuarantorStatus,
    getLoanDetails: jwt.getLoanDetails,
    editLoanDetails: jwt.editLoanDetails,
    payLoanRepayment: jwt.payLoanRepayment,
    loanUserAutocomplete: jwt.loanUserAutocomplete,
    GurantorAcceptDecline: jwt.GurantorAcceptDecline,
    getLoanPaymentDuration: jwt.getLoanPaymentDuration,
    viewGuarantors: jwt.viewGuarantors,
}

export function* GET_USER_LOAN() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanDashboard)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        data: success.data,
        loanSummary: success.data.loan_summary
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_USER_LOAN_STATUS() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanStatus)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        status: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* GET_USER_LOAN_ELIGIBILITY() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanEligibility)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        eligibility: success.eligibility || 0,
        waiver: success.waiver || 0,
        durationWaiver: success.duration_waiver || 0,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        eligibility: success.eligibility || 0,
        waiver: success.waiver || 0,
        durationWaiver: success.duration_waiver || 0,
      },
    })
  }
}


export function* GET_LOAN_FORM_FEE() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanFormFee)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanFormFee: success.data,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* GET_AVAILABLE_SAVINGS_BALANCE() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAvailableSavingsBalance)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        availableSavingsBalance: success.data,
        mainSavingsBalance: success.mainSavingsBala,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* LOAN_CALCULATOR({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanCalculator, payload.payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanCalculator: success.data,
      }
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanCalculator: payload.loanCalculator === 0 ? false : 0,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* PAY_LOAN_FORM_FEE({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payLoanFormFee, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    // yield put({
    //   type: 'loan/REQUEST_FOR_LOAN',
    //   payload: payload.requestData
    // })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* REQUEST_FOR_LOAN({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.requestForLoan, payload.requestData)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'loan/PAY_LOAN_FORM_FEE', payload: payload.paymentData})
    yield put({type: 'loan/GET_USER_LOAN'})
    yield put({type: 'loan/GET_ALL_LOANS'})
    yield put({type: 'loan/HANDLE_SECTION', payload: "home"})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* CANCEL_LOAN_REQUEST({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.cancelLoanRequest, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'loan/GET_USER_LOAN'})
    yield put({type: 'loan/GET_ALL_LOANS'})
    yield put({type: 'loan/HANDLE_SECTION', payload: "home"})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_ALL_LOANS() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllLoans)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        pendingLoan: Array.isArray(data?.pending) ? data?.pending : [data?.pending],
        ongoingLoan: Array.isArray(data?.ongoing) ? data?.ongoing : [data?.ongoing],
        completedLoan: Array.isArray(data?.completed) ? data?.completed : [data?.completed],
        cancelledLoan: Array.isArray(data?.cancelled) ? data?.cancelled : [data?.cancelled]
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


// export function* GET_GUARANTORS_STATUS() {
//   yield put({
//     type: 'loan/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const success = yield call(jwts.viewGuarantors)
//   console.log(success)
//   if (success.status) {
//     yield put({
//       type: 'loan/SET_STATE',
//       payload: {
//         loading: false,
//         loanFormFee: success.data,
//           // pendingGuarantorLoan: Array.isArray(data?.pending) ? data?.pending : [data?.pending],
//           // ongoingGuarantorLoan: Array.isArray(data?.ongoing) ? data?.ongoing : [data?.ongoing],
//           // completedGuarantorLoan: Array.isArray(data?.completed) ? data?.completed : [data?.completed],
//           // cancelledGuarantorLoan: Array.isArray(data?.cancelled) ? data?.cancelled : [data?.cancelled]
//       }
//     })
//   }
//   if (!success.status) {
//     yield put({
//       type: 'loan/SET_STATE',
//       payload: {
//         loading: false,
//       },
//     })
//   }
// }
export function* GET_GUARANTORS_STATUS() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.viewGuarantors)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        pendingGuarantorLoan: data?.pending_request,
        ongoingGuarantorLoan: data?.approved_request,
        cancelledGuarantorLoan: data?.cancelled_request
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_LOAN_DETAILS({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getLoanDetails, payload)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        guaranteedDetails: data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_GUARANTOR_STATUS({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getGuarantorStatus, payload)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        guaranteedStatus: data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_ALL_LOAN_DETAILS({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllLoanDetails, payload)
  const {data} = success
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        loanDetailsSummary: data?.loan_summary,
        loanDetailsTransaction: data?.loan_trans,
        loanDetailsGuarantor: data?.guarantors
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* EDIT_LOAN_DETAILS({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.editLoanDetails, payload)
  if (success.status || success?.message?.includes("success")) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'loan/HANDLE_SECTION', payload: "home"})
    yield put({type: 'loan/GET_USER_LOAN'})
    yield put({type: 'loan/GET_ALL_LOANS'})
    yield put({type: 'loan/GET_ALL_LOAN_DETAILS', payload: payload?.id})
    notification.success({
      message: "Success!",
      description: success?.message
    })
  }
  if (!success.status && !success?.message?.includes("success")) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success?.message
    })
  }
}

export function* GUARANTOR_ACCEPT_DECLAIN({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.GurantorAcceptDecline, payload)
  if (success.status || success?.message?.includes("success")) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'loan/GET_GUARANTORS_STATUS'})
    // yield put({type: 'loan/GET_ALL_LOANS'})
    // yield put({type: 'loan/GET_LOAN_DETAILS', payload: payload?.id})
    notification.success({
      message: "Success!",
      description: success?.message
    })
  }
  if (!success.status && !success?.message?.includes("success")) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success?.message
    })
  }
}

export function* PAY_LOAN_REPAYMENT({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.payLoanRepayment, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "loan/GET_USER_LOAN"})
    yield put({type: "loan/GET_ALL_LOANS"})
    yield put({type: "loan/GET_ALL_LOAN_DETAILS", payload: payload.id})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* GET_USER_AUTOCOMPLETE({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      searchLoading: false,
    }
  })
  const success = yield call(jwts.loanUserAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        searchLoading: false,
        userAutocomplete: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        searchLoading: false,
        userAutocomplete: []
      }
    })
    message.error({
      content: success.message,
      key: "updatable"
    })
  }
}

export function* GET_REPAYMENT_DURATION() {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      loading: false,
    }
  })
  const success = yield call(jwts.getLoanPaymentDuration)
  if (success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        repaymentDuration: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'loan/SET_STATE',
      payload: {
        loading: false,
        userAutocomplete: []
      }
    })
    message.error({
      content: success.message,
      key: "updatable"
    })
  }
}

export function* HANDLE_SECTION({payload}) {
  yield put({
    type: 'loan/SET_STATE',
    payload: {
      pageSection: payload
    }
  })
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_LOAN, GET_USER_LOAN),
    takeEvery(actions.GET_USER_LOAN_STATUS, GET_USER_LOAN_STATUS),
    takeEvery(actions.GET_USER_LOAN_ELIGIBILITY, GET_USER_LOAN_ELIGIBILITY),
    takeEvery(actions.GET_LOAN_FORM_FEE, GET_LOAN_FORM_FEE),
    takeEvery(actions.GET_AVAILABLE_SAVINGS_BALANCE, GET_AVAILABLE_SAVINGS_BALANCE),
    takeEvery(actions.LOAN_CALCULATOR, LOAN_CALCULATOR),
    takeEvery(actions.PAY_LOAN_FORM_FEE, PAY_LOAN_FORM_FEE),
    takeEvery(actions.REQUEST_FOR_LOAN, REQUEST_FOR_LOAN),
    takeEvery(actions.CANCEL_LOAN_REQUEST, CANCEL_LOAN_REQUEST),
    takeEvery(actions.GET_ALL_LOANS, GET_ALL_LOANS),
    takeEvery(actions.GET_ALL_LOAN_DETAILS, GET_ALL_LOAN_DETAILS),
    takeEvery(actions.EDIT_LOAN_DETAILS, EDIT_LOAN_DETAILS),
    takeEvery(actions.PAY_LOAN_REPAYMENT, PAY_LOAN_REPAYMENT),
    takeEvery(actions.GET_USER_AUTOCOMPLETE, GET_USER_AUTOCOMPLETE),
    takeEvery(actions.GET_LOAN_DETAILS, GET_LOAN_DETAILS),
    takeEvery(actions.GET_REPAYMENT_DURATION, GET_REPAYMENT_DURATION),
    takeEvery(actions.GUARANTOR_ACCEPT_DECLAIN, GUARANTOR_ACCEPT_DECLAIN),
    takeEvery(actions.HANDLE_SECTION, HANDLE_SECTION),
    takeEvery(actions.GET_GUARANTOR_STATUS, GET_GUARANTOR_STATUS),
    takeEvery(actions.GET_GUARANTORS_STATUS, GET_GUARANTORS_STATUS),
  ])
}
