/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowUpOutlined } from '@ant-design/icons'
import { BackTop, Layout, Input } from 'antd'
import { Bounce } from 'react-reveal'
import zim from '../images/zim.png'
import RefundComponent from '../refund'

const Foot = () => {
  const onSearch = value => console.log(value)
  const { Search } = Input
  return (
    <div className='w-100'>
      <Layout.Footer className="bg-black text-light ">
        <div className="container" style={{ color: '#FFF' }}>
          <Bounce bottom>
            <div className="row py-5 px-0">
              <div className="col-12 col-md-4">
                <img src={zim} alt="..." />
                <p className="">
                  <b className="fw-b">Address:</b>
                  <br />
                  Zero House, 1B Baba Yusuf Close Off Makinde Street
                  <br />
                  By Testing Ground Bus/Stop, Alausa Ikeja.
                </p>
                <p>
                  <b>Customer Service:</b> +234 818 093 0000
                  <br />
                  info@zerointerest.ng
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="fw-bold fs-5 pb-3">QUICK LINKS</div>
                <div className=" d-flex row">
                  <div className="col-12 col-md-6">
                    <Link to="/page/aboutus">
                      <h5 className="fs-6 text-light">About Us</h5>
                    </Link>
                    <Link to="/loan">
                      <h5 className="fs-6 text-light">Loan</h5>
                    </Link>
                    <Link to="/savings">
                      <h5 className="fs-6 text-light">Savings</h5>
                    </Link>
                    <Link to="/procurement">
                      <h5 className="fs-6 text-light">Procurement</h5>
                    </Link>
                    <Link to="/target-savings">
                      <h5 className="fs-6 text-light">Target Savings</h5>
                    </Link>
                  </div>
                  {/* <div>
                    <Divider type="vertical" className='bg-light ' style={{height:'100px'}} />
                  </div> */}
                  <div className="col-12 col-md-6">
                    <Link to="/auth/login">
                      <h5 className="fs-6 text-light">Withdrawal</h5>
                    </Link>
                    <RefundComponent />
                    <Link to="/page/terms-and-policy">
                      <h5 className="fs-6 text-light">Terms and Policy</h5>
                    </Link>
                    <Link to="/page/membership">
                      <h5 className="fs-6 text-light">Membership</h5>
                    </Link>
                    <Link to="/page/contactus">
                      <h5 className="fs-6 text-light">Contact Us</h5>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="fw-bold fs-5">SUPPORT</div>
                <div className="pt-3">
                  <a href="https://admin.zimcserver.com/">
                    <h5 className="fs-6 text-light">Admins</h5>
                  </a>
                </div>
                <div className="d-md-flex pt- justify-content-between">
                  Connect with us
                  <span
                    className="fa fa-facebook btn btn-light btn-lg rounded-circle mx-1"
                    style={{ fontSize: '17px' }}
                  />
                  <span
                    className="fa fa-linkedin btn btn-light btn-lg rounded-circle mx-1"
                    style={{ fontSize: '17px' }}
                  />
                  <span
                    className="fa fa-twitter btn btn-light btn-lg rounded-circle mx-1"
                    style={{ fontSize: '17px' }}
                  />
                  <span
                    className="fa fa-google-plus btn btn-light btn-lg rounded-circle mx-1"
                    style={{ fontSize: '17px' }}
                  />
                </div>
                <p>Subscribe</p>
                <Search placeholder="Sign up to recieve update" onSearch={onSearch} enterButton />
              </div>
            </div>
          </Bounce>
          <div>
            <BackTop>
              <ArrowUpOutlined className="btn btn-success btn-lg rounded-circle" />
            </BackTop>
          </div>
        </div>
      </Layout.Footer>
      <p
        className="text-center py-2 text-dark "
        style={{ backgroundColor: '#e7dede', minHeight: '40px' }}
      >
        Copyright © {new Date().getFullYear()}. Zero Interest Multi-Purpose Cooperative Society. All
        rights reserved.
      </p>

    </div>
  )
}

export default Foot
