import { Divider, Modal } from 'antd'
/* eslint-disable camelcase */
import React from 'react'


function SystemLogoutModal({
  dispatch,
  visible
}) {
  return (
    <Modal
      visible={visible}
      closable={false}
      // onCancel={()=> dispatch({type: "user/HANDLE_LOGOUT_MODAL", payload: false})}
      maskClosable={false}
      footer={false}
    >
      <div className='w-100'>
        <div className='w-100 text-center'>
          <div className=''>
            <img 
              alt='zero'
              src='/resources/images/logos/flex.png'
              className='img-fluid'
            />
          </div>
          <Divider type="horizontal" className="bg-dark" />
          <div className='container px-5'>
            <p className='fs-5 fw-bold'>are you sure you want to logout?</p>
            <div className='row'>
              <div className='col-md-6'>
                <button
                  className='btn btn-lg btn-danger rounded-pill'
                  type='button'
                  onClick={()=> dispatch({type: "user/HANDLE_LOGOUT_MODAL", payload: false})}
                >
                  Cancel
                </button>
              </div>
              <div className='col-md-6'>
                <button
                  className='btn btn-lg btn-success rounded-pill'
                  type='button'
                  onClick={()=> {dispatch({type: 'user/LOGOUT'}); dispatch({type: "user/HANDLE_LOGOUT_MODAL", payload: false})}}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SystemLogoutModal