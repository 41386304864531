/* eslint-disable camelcase */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './style.css'
import { MenuOutlined } from '@ant-design/icons'
import UserMenu from 'components/cleanui/layout/TopBar/UserMenu'
import zerologo from '../images/ZIMCS.png'


const Navbar = ({profileData}) => {
  const { pathname } = useLocation();
  const {
    profile_pic: img,
    first_name: fName,
    last_name: lName,
    phone_no: mobile,
    email,
    member_id: mId
  } = profileData && profileData
  const loggedIn = mId || false
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light fixed-top shadow" style={{zIndex: 1}}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={zerologo} alt="..." className="w-200p img-fluid" />
          </a>
          <button className="navbar-toggler btn btn-lg border" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <MenuOutlined />
          </button>
          <div className="collapse navbar-collapse pt-3" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" />
            <div className="d-md-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className={`nav-link fw-bold text-${pathname === "/" ? "dark" : "secondary"}`} to="/">HOME</Link>
                </li>
                <li className="nav-item">
                  <Link to="/page/whatwedo" className={`nav-link fw-bold text-${pathname === "/page/whatwedo" ? "dark" : "secondary"}`}>WHAT WE DO</Link>
                </li>
                <li className="nav-item">
                  <Link to="/page/membership" className={`nav-link fw-bold text-${pathname === "/page/membership" ? "dark" : "secondary"}`}>MEMBERSHIP</Link>
                </li>
                <li className="nav-item">
                  <Link to="/page/contactus" className={`nav-link fw-bold text-${pathname === "/page/contactus" ? "dark" : "secondary"}`}>CONTACT</Link>
                </li>
                <li className="nav-item">
                  <Link to="/page/procurement" className={`nav-link fw-bold text-${pathname === "/page/procurement" ? "dark" : "secondary"}`}>PROCUREMENT</Link>
                </li>
              </ul>
              {!loggedIn ?
                <div className='d-flex'>
                  <Link to="/auth/register" className="px-lg-5 mx-2 btn btn-outline-success">BECOME A MEMBER</Link>
                  <Link to="/auth/login" className="px-lg-5 mx-2 btn btn-success">SIGN IN</Link>
                </div> :
                <div className='px-2'>
                  <UserMenu
                    profileImage={img}
                    mId={mId}
                    email={email}
                    phone={mobile}
                    fullName={fName ? `${fName} ${lName}` : "user"}
                  />
                </div>
                }
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
