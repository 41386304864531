import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
  getSavingsData: jwt.getSavingsData,
  disableAutoSavePlan: jwt.disableAutoSavePlan,
  createAutoSavePlan: jwt.createAutoSavePlan,
  createQuickSave: jwt.createQuickSave,
  savingsTransaction: jwt.savingsTransaction,
  getAutoSavePlanDetails: jwt.getAutoSavePlanDetails,
  savingsWithdrawal: jwt.savingsWithdrawal,
  getWithdrawEligibility: jwt.getWithdrawEligibility,
  savingsBankWithdrawal: jwt.savingsBankWithdrawal,
}

export function* GET_REGULAR_SAVINGS() {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSavingsData)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        data: success.data,
        summary: success.data.auto_save_summary
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DISABLE_AUTO_SAVE_PLAN() {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.disableAutoSavePlan)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'savings/GET_REGULAR_SAVINGS'})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* CREATE_AUTO_SAVE_PLAN({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createAutoSavePlan, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'savings/GET_REGULAR_SAVINGS'})
    yield put({type: 'savings/GET_AUTO_SAVE_PLAN_DETAILS'})
    yield put({type: "savings/HANDLE_SECTION", payload: "home"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* CREATE_QUICK_SAVE({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createQuickSave, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: 'savings/GET_REGULAR_SAVINGS'})
    yield put({type: "savings/USER_SAVINGS_TRANSACTION"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* USER_SAVINGS_TRANSACTION({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.savingsTransaction, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        transactions: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_AUTO_SAVE_PLAN_DETAILS() {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAutoSavePlanDetails)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        autoSaveDetails: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}


export function* SAVINGS_WITHDRAWAL_ELIGIBILITY() {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getWithdrawEligibility)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        withdrawEligibility: success?.eligibility || 0,
        withdrawWaiver: success?.waiver || 0,
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}



export function* SAVINGS_WITHDRAWAL({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.savingsWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        withdrawalPinModal: payload?.destination === "bank" 
      }
    })
    yield put({type: 'savings/GET_REGULAR_SAVINGS'})
    yield put({type: 'savings/USER_SAVINGS_TRANSACTION'})
    notification.success({
      message: payload?.destination === "wallet" ? "Successful!" : "Request Sent!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* SAVINGS_WITHDRAWAL_TO_BANK({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.savingsBankWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        withdrawalPinModal: false 
      }
    })
    yield put({type: 'savings/GET_REGULAR_SAVINGS'})
    yield put({type: 'savings/USER_SAVINGS_TRANSACTION'})
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* HANDLE_OTP_MODAL({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      withdrawalPinModal: payload
    }
  })
}

export function* HANDLE_SECTION({payload}) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      pageSection: payload
    }
  })
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REGULAR_SAVINGS, GET_REGULAR_SAVINGS),
    takeEvery(actions.DISABLE_AUTO_SAVE_PLAN, DISABLE_AUTO_SAVE_PLAN),
    takeEvery(actions.CREATE_AUTO_SAVE_PLAN, CREATE_AUTO_SAVE_PLAN),
    takeEvery(actions.CREATE_QUICK_SAVE, CREATE_QUICK_SAVE),
    takeEvery(actions.USER_SAVINGS_TRANSACTION, USER_SAVINGS_TRANSACTION),
    takeEvery(actions.GET_AUTO_SAVE_PLAN_DETAILS, GET_AUTO_SAVE_PLAN_DETAILS),
    takeEvery(actions.SAVINGS_WITHDRAWAL, SAVINGS_WITHDRAWAL),
    takeEvery(actions.SAVINGS_WITHDRAWAL_TO_BANK, SAVINGS_WITHDRAWAL_TO_BANK),
    takeEvery(actions.SAVINGS_WITHDRAWAL_ELIGIBILITY, SAVINGS_WITHDRAWAL_ELIGIBILITY),
    takeEvery(actions.HANDLE_OTP_MODAL, HANDLE_OTP_MODAL),
    takeEvery(actions.HANDLE_SECTION, HANDLE_SECTION),
  ])
}
