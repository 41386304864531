/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, message, Modal, Select } from 'antd'
import moment from 'moment';
import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {monthData} from './month.json'


const mapStateToProps = ({dispatch, profile, user}) => ({
  dispatch,
  profileLoading: profile.loading,
  bankList: profile.bankList,
  bankAccount: profile.bankAccount,
  fileUploadUrl: user.fileUploadUrl,
  userLoading: user.loading,
  refundModal: user.refundModal,
})
function RefundComponent({
  dispatch,
  bankList,
  bankAccount,
  fileUploadUrl,
  userLoading,
  refundModal,
}) {
  const fileUploadData = new FormData()
  const {hash} = window.location
  const [inputDetails, setInputDetails] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    address: "",
    oracle_no: "",
    year: "",
    month: "",
    claim_amount: "",
    identification: "",
    account_name: bankAccount.account_name || "",
    account_no: bankAccount.account_number || "",
    bank_name: "",
    member_type: "",
    type: "",
    member_id: "",
    description: "",
  })

  const setRefundModal = payload => dispatch({type: "user/HANDLE_REFUND_MODAL", payload})
  // const { account_no: acctNo } = inputDetails
  useEffect(() => {
    setInputDetails({
      ...inputDetails,
      account_name: bankAccount.account_name,
      account_no: bankAccount.account_number,
      bank_name: inputDetails.bank_name
    })
  }, [bankAccount, inputDetails.bank_name])

  useEffect(() => {dispatch({type: "profile/GET_PROFILE_BANKS"})}, [dispatch])
  useEffect(() => {setInputDetails({...inputDetails, identification: fileUploadUrl})}, [fileUploadUrl])
  const handleFileUpload = (file) => {
    if(file[0]){
      fileUploadData.append("image_file", file[0])
      dispatch({type: 'user/GET_FILE_UPLOAD_URL', payload: fileUploadData})
    }else{
      message.error({
        content: "no file selected",
        duration: 4
      })
    }
  }
  return (
    <div className="w-100">
      <Link to={hash.slice(1)} onClick={()=>{setRefundModal(true)}}>
        <h5 className="fs-6 text-light">Request Refund</h5>
      </Link>

      <Modal
        title="Request For Refund"
        visible={refundModal}
        maskClosable={false}
        style={{top: 20}}
        onCancel={()=>setRefundModal(false)}
        footer={false}
        width="80%"
      >
        <div className='w-full'>
          <div className='container'>
            <Form className='row' layout='vertical' onFinish={()=>dispatch({type: "user/REQUEST_REFUND", payload: inputDetails})}>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Full Name"
                  name="full_name"
                  rules={[{required: true}]}
                >
                  <Input placeholder='full name' size='large' value={inputDetails.full_name} onChange={({target:{value}})=>setInputDetails({...inputDetails, full_name: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{required: true}]}
                >
                  <Input type='email' placeholder='email' size='large' value={inputDetails.email} onChange={({target:{value}})=>setInputDetails({...inputDetails, email: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Mobile Number"
                  name="phone_no"
                  rules={[{required: true}]}
                >
                  <Input type='number' minLength={10} placeholder='phone number' size='large' value={inputDetails.phone_no} onChange={({target:{value}})=>setInputDetails({...inputDetails, phone_no: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Address"
                  name="address"
                >
                  <Input placeholder='your address' size='large' value={inputDetails.address} onChange={({target:{value}})=>setInputDetails({...inputDetails, address: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Refund Type"
                  name="type"
                  rules={[{required: true}]}
                >
                  <Select placeholder='refund type' size='large' value={inputDetails.type} onChange={(value)=>setInputDetails({...inputDetails, type: value})}>
                    <Select.Option value="cheque">Cheque</Select.Option>
                    <Select.Option value="deduction">Monthly deduction</Select.Option>
                    <Select.Option value="deactived">Closed Member/Monthly deduction</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Membership Number/Id"
                  name="member_id"
                  rules={[{required: !!inputDetails.type?.includes("cheque")}]}
                >
                  <Input disabled={inputDetails.type?.includes("deduction")} placeholder='membership number or id' size='large' onChange={({target:{value}})=>setInputDetails({...inputDetails, member_id: value?.includes("ZEROCOOP") ? value: `ZEROCOOP-${value}`})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Oracle Number"
                  name="oracle_no"
                  // rules={[{required: true}]}
                  rules={[{required: !!inputDetails.type?.includes("deduction")}]}
                >
                  <Input placeholder='oracle number' size='large' value={inputDetails.oracle_no} onChange={({target:{value}})=>setInputDetails({...inputDetails, oracle_no: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Deducted Month"
                  name="month"
                  rules={[{required: true}]}
                >
                  {/* <DatePicker className='w-100' placeholder='Deducted Month' size='large' value={moment(inputDetails.month || "")} onChange={(string, value)=>setInputDetails({...inputDetails, month: value})} /> */}
                  <Select className='w-100' placeholder='Deducted Month' size='large' value={inputDetails.month} onChange={(value)=>setInputDetails({...inputDetails, month: value})}>
                    {monthData.map(d=>(
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Deducted Year"
                  name="year"
                  rules={[{required: true}]}
                >
                  <DatePicker className='w-100' placeholder='Deducted Month' picker="year" size='large' value={moment(inputDetails.year || "")} onChange={(string, value)=>setInputDetails({...inputDetails, year: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Deducted Amount"
                  name="claim_amount"
                  rules={[{required: true}]}
                >
                  <Input type='number' placeholder='deducted amount' size='large' value={inputDetails.claim_amount} onChange={({target:{value}})=>setInputDetails({...inputDetails, claim_amount: value})} />
                </Form.Item>
              </div>
              {/* <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Refundable Amount"
                  name="refundable_amount"
                  rules={[{required: true}]}
                >
                  <Input type='number' placeholder='refundable amount' size='large' value={inputDetails.refundable_amount} onChange={({target:{value}})=>setInputDetails({...inputDetails, refundable_amount: value})} />
                </Form.Item>
              </div> */}
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Form of Identification"
                  name="identification"
                  rules={[{required: true}]}
                >
                  <Input type='file' placeholder='identification' accept=".jpg, .jpeg, .png, .pdf" onChange={({target: {files}})=> handleFileUpload(files)} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Account Number"
                  name="account_no"
                  rules={[{required: true}]}
                >
                  <Input size='large' maxLength={10} placeholder='account number' value={inputDetails.account_no} onChange={({target: {value}})=> setInputDetails({...inputDetails, account_no: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item 
                  label="Recipient Bank"
                  name="bank_code"
                >
                  <select
                    placeholder="select bank"
                    // value={inputDetails.bank}
                    className='form-control'
                    disabled={!inputDetails.account_no}
                    value={inputDetails.bank_name}
                    onChange={(name)=> {
                      const code = bankList?.find(e=>e.name===name.target.value)?.code
                      setInputDetails({...inputDetails, bank_name: name.target.value});
                      dispatch({type: 'profile/GET_BANKS_ACCOUNT', payload: {account_no: inputDetails.account_no, code}});
                    }}
                  >
                    {/* > */}
                    {bankList.map((val)=>(
                      <option key={val.code} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </select>                
                </Form.Item> 
                {/* <Form.Item
                  label="Recipient Bank"
                  name="bank_code"
                  rules={[{required: true}]}
                >
                  <Select
                    placeholder="select bank"
                    disabled={!inputDetails.account_no}
                    value={inputDetails.bank_name}
                    size='large'
                    onChange={(name)=> {
                      const code = bankList?.find(e=>e.name===name)?.code
                      setInputDetails({...inputDetails, bank_name: name});
                      dispatch({type: 'profile/GET_BANKS_ACCOUNT', payload: {account_no: inputDetails.account_no, code}});
                    }}
                  >
                    {
                        bankList.length && bankList.map((val)=>(
                          <Select.Option value={val.name}>
                            <button type='button' className='text-start w-100 h-100 bg-transparent m-0 p-0 border-0'>
                              {val.name}
                            </button>
                          </Select.Option>
                        ))
                      }
                  </Select>
                </Form.Item> */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Account Name"
                  name="account_name"
                >
                  <Input size='large' placeholder={inputDetails.account_name || 'account name'} disabled value={inputDetails.account_name} onChange={({target:{value}})=> setInputDetails({...inputDetails, account_name: value})} />
                </Form.Item>
              </div>
              <div className='col-sm-6 col-md-4'>
                <Form.Item
                  label="Extra Description"
                  name="description"
                >
                  <Input size='large' placeholder="any extra description" value={inputDetails.description} onChange={({target:{value}})=>setInputDetails({...inputDetails, description: value})} />
                </Form.Item>
              </div>
              <div className='pt-4 w-50 mx-auto'>
                <Button loading={userLoading} type='primary' size='large' block htmlType='submit'>
                  Proceed
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default connect(mapStateToProps)(RefundComponent)
