/* eslint-disable camelcase */
import actions from './actions'

const initialState = {
  data: {},
  summary: {},
  loading: false,
  pageSection: "home",
  transactions: [],
  autoSaveDetails: {},
  withdrawEligibility: 0,
  withdrawWaiver: 0,
  withdrawalPinModal: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
