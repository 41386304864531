/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'profile/SET_STATE',
  GET_USER_PROFILE: 'profile/GET_USER_PROFILE',
  UPDATE_USER_PROFILE: 'profile/UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_PIC: 'profile/UPDATE_USER_PROFILE_PIC',
  UPDATE_USER_PASSWORD: 'profile/UPDATE_USER_PASSWORD',
  GET_PROFILE_INPUTS: 'profile/GET_PROFILE_INPUTS',
  GET_PROFILE_BANKS: 'profile/GET_PROFILE_BANKS',
  GET_BANKS_ACCOUNT: 'profile/GET_BANKS_ACCOUNT',
  ADD_USER_BANK_DETAILS: 'profile/ADD_USER_BANK_DETAILS',
  DELETE_USER_DEBIT_CARD: 'profile/DELETE_USER_DEBIT_CARD',
  GET_ACCOUNT_STATEMENT: 'profile/GET_ACCOUNT_STATEMENT',
  HANDLE_FOCUSED_TASK: 'profile/HANDLE_FOCUSED_TASK',
}

export default actions
