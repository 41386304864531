/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { Bounce } from 'react-reveal'
// import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  // logo,
  // isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}
        <div
          className={`${classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}`}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
          }}
        >
          {/* <Fade top>
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.logoContainer}>
                <Link to="/" className={`${style.logo} text-decoration-none`}>
                  <img src="resources/images/logo.svg" className="mr-2" alt="zero" />
                  <div className={style.name}>{logo}</div>
                  {logo === 'zero Pro' && <div className={style.descr}>React</div>}
                </Link>
              </div>
              <div className="d-none d-sm-block">
                <span className="mr-2">Don&#39;t have an account?</span>
                <Link to="/auth/register" className="font-size-16 kit__utils__link">
                  Sign up
                </Link>
              </div>
            </div>
          </Fade> */}
          {/* ++++++++++++++++++++++++Main body========================= */}

          <div className={style.containerInner}>{children}</div>

          {/* foooter */}
          <div className="mt-auto pt-5">
            <Bounce bottom>
              <div className="text-center text-white">
                Copyright © {new Date().getFullYear()} zero
                {/* |{' '}
                <a href="https://www.mediatec.org/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a> */}
              </div>
            </Bounce>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
