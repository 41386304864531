/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable camelcase */
import React from 'react';
 
const ActionButton = ({img, title, handle, visible, focus}) => {
  return (
    <button autoFocus type='button' className='w-100 border-0 bg-transparent' onClick={handle}>
      <div className={`btn-action my-2 text-decoration-none rounded-15p border py-2 px-4 ${visible?'d-flex' : 'd-none'} justify-content-between `}>
        <img alt='zero' src={img} width="35px" />
        <div className='d-flex pt-2'>
          <p className='text-dark fs-6 m-0 mx-3'>{title}</p>
          {
            focus && (
              <div className="spinner-grow mt-1 bg-success" style={{width: "0.9rem", height: "0.9rem",}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )
          }
        </div>
      </div>
    </button>
  );
}
 
export default ActionButton