/* eslint-disable camelcase */
import actions from './actions'

const initialState = {
  data: {},
  loanSummary: {},
  editLoanDetail: {},
  loading: false,
  searchLoading: false,
  pageSection: "home",
  status: 1,
  eligibility: 0,
  waiver: 0,
  durationWaiver: 0,
  loanFormFee: 0,
  availableSavingsBalance: 0,
  loanCalculator: 0,
  mainSavingsBalance:0,
  pendingLoan: [],
  ongoingLoan: [],
  completedLoan: [],
  cancelledLoan: [],
  loanDetailsSummary: {},
  loanDetailsTransaction: [],
  loanDetailsGuarantor: {},
  userAutocomplete: [],
  guaranteedStatus:[],
  guaranteedDetails:{},
  pendingGuarantorLoan: [],
  ongoingGuarantorLoan: [],
  cancelledGuarantorLoan: [],

  repaymentDuration: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
