import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
/* eslint-disable camelcase */
import actions from './actions'

const jwts = {
    userShare: jwt.userShare,
    fundShare: jwt.fundShare,
    getSharePaymentMethod: jwt.getSharePaymentMethod,
    shareWithdrawal: jwt.shareWithdrawal,
    shareBankWithdrawal: jwt.shareBankWithdrawal,
}

export function* GET_USER_SHARE({payload}) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.userShare, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
        data: success.data.share_balance,
        transactions: success.data.share_transactions
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FUND_USER_SHARE({payload}) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.fundShare, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "share/GET_USER_SHARE"})
    yield put({type: "user/GET_USER_CARDS"})
    notification.success({
      message: "Successful!",
      description: success.message
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* GET_SHARE_PAYMENT_METHOD() {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSharePaymentMethod)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
        paymentMethods: success.data
      }
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* WITHDRAW_USER_SHARE({payload}) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.shareWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "share/GET_USER_SHARE"})
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: true
    })
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export function* WITHDRAW_USER_SHARE_TO_BANK({payload}) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.shareBankWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({type: "share/GET_USER_SHARE"})
    yield put({
      type: 'savings/HANDLE_OTP_MODAL',
      payload: false
    })
    notification.success({
      message: "Successful!",
      description: success.message,
      duration: 5
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success.message
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_SHARE, GET_USER_SHARE),
    takeEvery(actions.FUND_USER_SHARE, FUND_USER_SHARE),
    takeEvery(actions.GET_SHARE_PAYMENT_METHOD, GET_SHARE_PAYMENT_METHOD),
    takeEvery(actions.WITHDRAW_USER_SHARE, WITHDRAW_USER_SHARE),
    takeEvery(actions.WITHDRAW_USER_SHARE_TO_BANK, WITHDRAW_USER_SHARE_TO_BANK),
  ])
}
