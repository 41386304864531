/* eslint-disable camelcase */
const actions = {
    SET_STATE: 'target/SET_STATE',
    GET_TARGET_SAVINGS: 'target/GET_TARGET_SAVINGS',
    GET_TARGET_SAVINGS_PLANS: 'target/GET_TARGET_SAVINGS_PLANS',
    GET_TARGET_SAVINGS_PLAN_LISTS: 'target/GET_TARGET_SAVINGS_PLAN_LISTS',
    DISABLE_AUTO_SAVE_PLAN: 'target/DISABLE_AUTO_SAVE_PLAN',
    CREATE_TARGET_PLAN: 'target/CREATE_TARGET_PLAN',
    STOP_TARGET_PLAN: 'target/STOP_TARGET_PLAN',
    GET_TARGET_PLAN_DETAILS: 'target/GET_TARGET_PLAN_DETAILS',
    GET_SINGLE_TARGET_PLAN_DETAILS: 'target/GET_SINGLE_TARGET_PLAN_DETAILS',
    CREATE_TARGET_QUICK_SAVE: 'target/CREATE_TARGET_QUICK_SAVE',
    GET_TARGET_FEE: 'target/GET_TARGET_FEE',
    PAY_TARGET_FEE: 'target/PAY_TARGET_FEE',
    TARGET_SAVINGS_WITHDRAWAL: 'target/TARGET_SAVINGS_WITHDRAWAL',
    TARGET_SAVINGS_WITHDRAWAL_TO_BANK: 'target/TARGET_SAVINGS_WITHDRAWAL_TO_BANK',
    HANDLE_SECTION: 'target/HANDLE_SECTION',
  }

  export default actions
