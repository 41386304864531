import React, { Fragment, useMemo } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import store from 'store'
// import Loader from 'components/cleanui/layout/Loader'
import SystemLogoutModal from 'components/kit/widgets/Modals/logout'
import DisclaimerModal from 'components/kit/widgets/Modals/disclaimer'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ dispatch, user }) => ({ dispatch, user })
let previousPath = ''

const Layout = ({
  dispatch,
  user: { logoutModal, disclaimerModal },
  children,
  location: { pathname, search },
}) => {
  // wema alat pay success vend request
  setInterval(() => {
    const { status, data: payParam } = JSON.parse(
      sessionStorage.getItem('alatpay_payment_success'),
    ) || { status: false }
    const { type, payload: data } = JSON.parse(sessionStorage.getItem('alatpay_success_param')) || {
      type: false,
      payload: {},
    }
    if (status) {
      const payload = Object.assign(
        {},
        ...Object.entries(data).map(([key, val]) => ({
          [key]:
            typeof val === 'object'
              ? Object.assign(
                  {},
                  ...Object.entries(val).map(([k, v]) => ({ [k]: payParam?.[v] || v })),
                )
              : payParam?.[val] || val,
        })),
      )
      dispatch({ type, payload })
      sessionStorage.removeItem('alatpay_success_param')
      sessionStorage.removeItem('alatpay_payment_success')
    }
  }, 1000)

  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (/^\/page(?=\/|$)/i.test(pathname) || pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = store.get('zero-user') || false
  const isUserLoading = false
  const isAuthLayout = getLayout() === 'auth'
  const isMainLayout = getLayout() === 'main'
  const isPublicLayout = getLayout() === 'public'

  const isDisclaim = new Date().getFullYear() <= 2024

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (isMainLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  useMemo(() => {
    dispatch({ type: 'user/DISCLAIMER_MODAL', payload: isPublicLayout && isDisclaim })
  }, [isPublicLayout, isDisclaim, dispatch])

  return (
    <Fragment>
      <Helmet titleTemplate="zero" title="interest" />
      <SystemLogoutModal visible={logoutModal} dispatch={dispatch} />
      <DisclaimerModal visible={disclaimerModal} dispatch={dispatch} />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
