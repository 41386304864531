/* eslint-disable camelcase */
const actions = {
  SET_STATE: 'wallet/SET_STATE',
  GET_USER_WALLET: 'wallet/GET_USER_WALLET',
  FUND_USER_WALLET: 'wallet/FUND_USER_WALLET',
  REQUEST_REFUND: 'wallet/REQUEST_REFUND',
  WITHDRAW_USER_WALLET: 'wallet/WITHDRAW_USER_WALLET',
  WITHDRAW_USER_WALLET_TO_BANK: 'wallet/WITHDRAW_USER_WALLET_TO_BANK',
  GET_PAYMENT_METHOD: 'wallet/GET_PAYMENT_METHOD',
}

export default actions
